<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_inline_block">
          <div class="mainList_operation_search">
            <el-input
              placeholder="请输入内容"
              clearable
              v-model="condition.val"
              class="input-with-select"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="pageChangeHandler(1)"
              ></el-button>
            </el-input>
          </div>
        </div>
        <div class="fr">
          <el-button type="primary" icon="el-icon-plus" @click="Add()">新增</el-button>
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <!-- 权限列表 -->
          <el-table
            ref="multipleTable"
            :data="tableData"
            row-key="id"
            default-expand-all
            :highlight-current-row="true"
            height="string"
            border
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            style="width: 100%"
          >
            <el-table-column label="角色名称" align="left" prop="roleName"> </el-table-column>
            <el-table-column label="角色标识" align="center" prop="roleType"> </el-table-column>
            <el-table-column label="操作" align="center">
              <!-- <template slot="header" slot-scope="scope">
                          <el-input
                            v-model="search"
                            size="mini"
                            placeholder="输入关键字搜索"/>
                        </template> -->
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="text"
                  class="text_Edit_Bgc"
                  @click="Edit(scope.$index, scope.row)"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  size="mini"
                  class="text_Grant_Bgc"
                  @click="roleAuthorization(scope.$index, scope.row)"
                  >授权</el-button
                >
                <el-button
                  type="text"
                  size="mini"
                  class="text_Remove_Bgc"
                  @click="Delete(scope.$index, scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>

          <el-pagination
            @current-change="pageChangeHandler"
            @size-change="handleSizeChange"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            :total="parameter.total"
            ><span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>
    <Edit
      width="600px"
      :options="options"
      :isShow.sync="showEditDialog"
      :treeselectOptions="treeselectOptions"
    >
    </Edit>

    <EditDialog
      width="50%"
      :isShow.sync="showRoleAuthorization"
      :loading.sync="roleAuthorizationLoading"
      @submit="roleAuthorizationSubmit"
    >
      <div slot="title" class="title">角色授权</div>
      <div slot="content" class="content" v-loading="roleAuthorizationContentLoading">
        <el-alert title="勾选菜单需勾选到最末级" type="warning"></el-alert>
        <el-tree
          ref="tree"
          show-checkbox
          node-key="id"
          :data="allTreeData"
          :default-checked-keys="selectedTreeData"
          :props="{ children: 'children', label: 'menuName' }"
          :expand-on-click-node="false"
          :check-on-click-node="true"
          :check-strictly="true"
          accordion
        >
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span>
              {{ node.label }}
              <span v-if="data.nickname" style="opacity: 0.7">({{ data.nickname }})</span>
            </span>
            <span>
              <el-tag v-if="data.typeId == 0" class="menu-tag-style">菜单权限</el-tag>
              <el-tag v-if="data.typeId == 1" class="menu-tag-style" type="success"
                >按钮权限</el-tag
              >
            </span>
          </span>
        </el-tree>
      </div>
    </EditDialog>
  </div>
</template>
<script>
import { initTreeData } from '@/util/common'
import { mapState } from 'vuex'

export default {
  components: {
    Edit: () => import('./Edit.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    Crumbs: () => import('@/components/Crumbs.vue'),
  },
  data() {
    return {
      parameter: {
        condition: {},
        pageNow: 1,
        pageSize: 50,
        total: 0,
      },
      pageSize: 0,
      condition: {
        key: 'userName',
        val: '',
      },
      userData: [],
      form: {},
      loading: false,
      showEditDialog: false,
      editDialogLoading: false,

      tableData: [],
      treeselectOptions: [],
      search: '',
      options: {},
      showRoleAuthorization: false,
      roleAuthorizationContentLoading: false,
      roleAuthorizationLoading: false,
      allTreeData: [],
      selectedTreeData: [],
    }
  },
  watch: {
    tableData: {
      // immediate:true,//初始化立即执行
      deep: true,
      handler: function (newVal, oldVal) {
        let num = newVal.length
        if (num === 0 && this.parameter.pageNow > 1 && this.parameter.pageNow !== 1) {
          this.parameter.pageNow -= 1
          this.getData()
        }
      },
    },
  },
  provide() {
    return {
      refresh: this.refresh,
    }
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getData()
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  methods: {
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    //全局分页保存
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }

      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    getData() {
      this.loading = true

      this.parameter.condition = {}
      // this.parameter.condition[this.condition.key] = this.condition.val;

      this.$api.role
        .listRole(this.parameter)
        .then(res => {
          this.loading = false
          this.tableData = res.data

          this.tableData = initTreeData(this.tableData)
          this.treeselectOptions = [
            {
              id: 0,
              roleName: '顶级角色',
              children: this.tableData,
            },
          ]

          this.parameter.total = res.data.total
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    Add() {
      this.options = {
        // isSystem:'N'
      }
      this.showEditDialog = true
      // this.$nextTick(() => {
      //     this.$refs.form.clearValidate();
      // });
    },
    Edit(index, row) {
      this.options = row.deepClone()
      this.showEditDialog = true
    },
    Delete(index, row) {
      this.$confirm('将删除' + row.roleName + '角色, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$api.role
            .del(row.id)
            .then(res => {
              this.tableData.splice(index)
              this.$message({
                type: 'success',
                message: '删除成功!',
              })
            })
            .catch(err => {
              this.submitLoading = false
              console.log(err)
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    refresh() {
      this.showEditDialog = false
      this.getData()
    },
    roleAuthorizationSubmit() {
      this.roleAuthorizationLoading = true

      var params = {
        menuIds: [],
        roleId: this.activeId,
      }
      this.$refs.tree.getCheckedNodes().forEach(item => {
        params.menuIds.push(item.id)
      })
      this.$api.role
        .saveUserRole(params)
        .then(res => {
          this.roleAuthorizationLoading = false
          this.showRoleAuthorization = false
          this.$message.success('操作成功！')
        })
        .catch(err => {
          this.roleAuthorizationLoading = false
          console.log(err)
        })
    },
    roleAuthorization(index, row) {
      let id = row.id
      this.showRoleAuthorization = true
      this.roleAuthorizationContentLoading = true
      this.allTreeData = []
      this.selectedTreeData = []
      this.activeId = id
      this.$api.menu
        .queryMenuByRoleId(id)
        .then(res => {
          this.allTreeData = initTreeData(res.data.all)
          if (res.data.list && res.data.list.length > 0) {
            for (var i in res.data.list) {
              this.selectedTreeData.push(res.data.list[i].id)
            }
          }
          this.roleAuthorizationContentLoading = false
        })
        .catch(err => {
          this.roleAuthorizationContentLoading = false
          console.log(err)
        })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
/deep/.menu-tag-style {
  line-height: 20px;
  height: 20px;
}
</style>
